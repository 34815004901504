.container {
    display: flex;
    gap: 1.25rem;
    justify-content: center;
    height: calc(100% - 3rem);

    .page {
        background: #fff;
        border-radius: .5714285714rem;
        padding: 1.5rem 2.5rem;
    }

    .left {
        align-items: center;
        display: flex;
        flex-direction: column;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        width: 22.5em;
        height: 42rem;
    }

    .right {
        width: 58.875em;
    }

    .tabsWrapper {
        display: flex;
        gap: 2rem;
    }
}