.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
}


.content {
    display: flex;
    width: calc(100% - 4.5rem * 2);
    height: 100%;
    margin: 1.2rem 4.5rem 0;
    flex-direction: column;
}