@import 'styles/variables.scss';
@import 'styles/rem.scss';

.publicInterviewsFilters {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 1rem;
    grid-row-gap: 1.54rem;
    margin-top: 4.375rem;
    font-size: 0.875rem !important;

    & .item {
        height: 2.875rem;
    }

    .input {
        min-width: 14rem;
        flex: 1;
    }
    .format {
        width: 10rem;
    }
    .type {
        width: 16.625rem;
    }
    .market {
        width: 15.625rem;
    }
    .duration {
        width: 11.25rem;
    }
    .sorter {
        width: 13rem;
    }
    .resetButton {
        width: 6.75rem;
        margin: 0;
        height: 2.875rem;
        padding: 0;
        font-size: 0.875rem;
        border-radius: 0.375rem;
        span {
            text-align: center;
        }
    }
    .view {
        width: 6rem;
    }
    .tooltip {
        width: 8rem;
        bottom: 3rem;
        text-align: center;
    }
}
