.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;

    .chat {
        min-height: 45rem;
        width: calc(100% - 28rem);
        height: 100%;
        display: flex
    }

    .chatContainer {
        height: calc(100% - 2rem);
    }
}


.content {
    display: flex;
    width: calc(100%);
    height: 100%;
    margin: 0 14rem;
    margin-top: 1.7rem;
    flex-direction: column;
    // align-items: center;

}