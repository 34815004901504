.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100%;

    .titles {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .tabs {
        display: flex;
        gap: 3rem;
        margin-top: 1rem;

        :first-child {
            width: 8rem !important;
        }
    }
}

.form {
    height: 100%;
    background: white;
    margin-top: -1.5rem;
    padding: 2.125rem 2.375rem;
}

.questions {
    padding: 1.5rem 2.375rem;
    display: flex;
    justify-content: space-between;
}

