@import 'styles/rem.scss';

.featuredInterviews {
    & > span {
        display: flex;
        justify-content: center;
        font-weight: 600;
        font-size: 1.875rem;
    }
    .gridRow {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 2.2rem;
        grid-row-gap: 2.5rem;
        & > span {
            width: 26.25rem;
            @media (max-width: rem(1335px)) {
                width: 23.25rem;
            }
            @media (max-width: rem(1200px)) {
                width: 26.25rem;
            }
        }
    }
}

.title {
    height: rem(50px);
    width: rem(294px);
    border-radius: rem(8px);
    margin: auto;
}

.item {
    height: 20.96rem;
    border-radius: rem(8px);
}
