.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5rem;
    width: 100%;
    min-height: 5rem;
    position: relative;
    gap: 4rem;

    .text {
        width: 10rem;
    }


    .langs {
        display: flex;
        width: 12rem;
        height: 3rem;
    }

    .logo {
        margin-right: 1.5rem;
        width: 1.4rem;
        height: 1.4rem;
    }
}



.logoWrapper {
    display: flex;

}


.left {
    display: flex;
    width: 100%;
    gap: 4rem;
}

.right {
    display: flex;
    width: 100%;
    justify-content: end;
}