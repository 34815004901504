.content {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7rem;
    gap: 1.4285714286rem;
}

.tabs {
    display: flex;
    gap: 2.8571428571rem;
    margin-top: 2.8571428571rem;

    .tab {
        width: 21rem;
        height: 20rem;
    }
}