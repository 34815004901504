@import 'styles/variables.scss';
@import 'styles/rem.scss';

.profilePage {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0rem 5rem 3rem 5rem;

    & > div {
        padding: 1.5rem 2.5rem;
        background: #fff;
        border-radius: rem(8px);
        box-shadow: $shadow-thick;
    }
}

.notFound {
    width: fit-content;
    margin: 6rem auto 0 auto;
    padding: 3rem;
    font-size: 3rem;
    border-radius: rem(6px);
    box-shadow: 0 rem(-2px) rem(20px) 0 rgba(0, 0, 0, 0.1);
    background-color: #f1f2f5;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1.25rem;
    width: 20.5em;
    position: sticky;
    top: 0;

    & * {
        border-radius: rem(8px);
    }

    .profileCardAvatar {
        margin-bottom: 1rem;
        width: 7.75rem;
        height: 7.75rem;
    }

    .profileCardName {
        width: rem(190px);
        height: rem(39px);
    }

    .profileCardRole {
        width: rem(190px);
        height: rem(24px);
        margin-bottom: rem(26px);
    }

    .cardInfoRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        width: 100%;
        & .item {
            height: rem(24px);
        }
    }

    .btn {
        width: 100%;
        margin-top: rem(20px);

        & .inner {
            height: rem(52px);
        }
    }
}

.info {
    width: 54em;
    position: relative;

    & .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 0.25rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(181, 181, 182, 0.15);
        margin-bottom: rem(75px);

        & .date {
            width: rem(180px);
            height: rem(20px);
        }

        & .tabs {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            & .tab {
                width: rem(50px);
                height: rem(26px);
                margin-right: 2.5rem;
            }
        }
    }

    & .section {
        display: flex;
        border-bottom: 1px solid rgba(181, 181, 182, 0.15);
        padding: 2rem 0;
        &.first {
            padding-top: 0;
        }
        &:last-child {
            border-bottom: unset;
        }
        & .title {
            height: rem(24px);
            width: rem(100px);
        }

        & .data {
            margin-left: rem(102px);

            & .value {
                height: rem(24px);
                width: rem(477px);
                &.last {
                    width: rem(311px);
                }
            }
        }
    }
}
