.content {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    align-items: center;
    margin-top: -1rem;

    .headerItems {
        display: flex;
        gap: 3rem;

        .headerItem {
            width: 5rem;
            height: 1rem;
        }
    }
}

.section {
    width: 58rem;
    background: white;
    display: flex;
    border-radius: .625rem;
    // padding: 2.5rem;
    padding: 0.5rem 2.5rem .25rem;
}
