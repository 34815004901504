@import 'styles/rem.scss';

.container {
    display: flex;
    flex-direction: column;
    padding-top: rem(16px);
    margin-bottom: rem(20px);
    & .row {
        display: flex;
        justify-content: space-between;

        & .item {
            width: rem(143px);
            height: rem(48px);
        }
    }

    .line {
        width: 100%;
        margin-top: rem(35px);
        height: rem(52px);
    }
}

.list {
    display: flex;
    flex-direction: column;
    .item {
        height: rem(122px);
        margin-bottom: rem(16px);
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}
