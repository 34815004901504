.tabs {
    display: flex;
    justify-content: space-between;
    width: 20rem;
    height: 2rem;
    gap: 2rem;

    & .tab {
        width: rem(143px);
        height: rem(48px);
    }
}

.list {
    margin-top: 1rem;
}

.search {
    width: 31rem;
    height: 3rem;
    margin-top: 1rem;
}

.content {
    display: flex;
    width: calc(100% - 10rem);
    height: 100%;
    margin: 0 5rem;
    flex-direction: column;
}