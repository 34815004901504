@import 'styles/rem.scss';

.publicInterviewsList {
    z-index: 0;
    display: flex;
    flex-direction: column;

    & .item {
        height: rem(160px);
        width: 100%;
        margin-top: 2.5rem;
    }
}

@import 'styles/rem.scss';

.publicInterviewsList {
    z-index: 0;
    display: flex;
    flex-direction: column;

    & .item {
        height: rem(160px);
        width: 100%;
        margin-top: 2.5rem;
    }

    & .card {
        height: 31.75rem;
        width: 100%;
        margin-top: 2.5rem;
        span {
            height: 100%;
        }
    }
    &.verticalView {
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, 26.25rem);
        grid-template-rows: max-content;
        grid-column-gap: 1rem;
        justify-content: space-between;
        justify-items: center;
        align-items: center;
        grid-row-gap: 2.5rem;
        margin-top: 2.5rem;
        @media (max-width: rem(1750px)) {
            grid-column-gap: 3rem;
        }
        @media (max-width: rem(1434px)) {
            grid-template-columns: repeat(auto-fill, 23.25rem);
        }
        @media (max-width: rem(1276px)) {
            grid-template-columns: repeat(auto-fill, 26.25rem);
            justify-content: center;
        }
    }
}
