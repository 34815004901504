@import 'styles/rem.scss';

.pagination {
    display: flex;
    margin-top: rem(40px);
    justify-content: space-between;

    & .item {
        height: rem(44px);
        &:nth-child(1) {
            width: rem(202px);
        }
        &:nth-child(3) {
            width: rem(100px);
        }
        &:nth-child(5) {
            width: rem(260px);
        }
    }
}
