.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;

    .header {
        align-items: center;
        background: #fff;
        box-shadow: 0 .3571428571rem 1.7142857143rem rgba(110, 128, 153, .3);
        display: flex;
        font-size: .875rem;
        height: 3.75rem;
        padding: 1rem 1.25rem;
        width: 100%;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-top: -4rem;
        gap: 1rem;
    }
}