.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;

    .calendar {
        min-height: 45rem;
        width: calc(100% - 25rem);
        height: 100%;
        display: flex
    }

    .calendarContainer {
        height: 100%;
    }
}


.content {
    display: flex;
    width: calc(100%);
    height: 100%;
    margin: 0 12.5rem;
    margin-top: 2rem;
    flex-direction: column;
    // align-items: center;

}