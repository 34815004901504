@import 'styles/rem.scss';

.wrapper {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .spinner {
        display: inline-block;
        position: relative;
        width: rem(64px);
        height: rem(64px);

        &:after {
            content: ' ';
            display: block;
            border-radius: 50%;
            width: 0;
            height: 0;
            margin: rem(6px);
            box-sizing: border-box;
            border: rem(26px) solid #24ce6e;
            border-color: rgb(8, 209, 139) transparent rgb(8, 209, 139) transparent;
            animation: spinner 1.2s infinite;
        }
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        transform: rotate(1800deg);
    }
}
