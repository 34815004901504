@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;400;600&display=swap');

* {
    box-sizing: border-box;
}

:root {
    --main-font: 12.8px;
}

body,
html {
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.65;
    letter-spacing: normal;
    color: #1c1b1f;
    background-color: rgba(241, 242, 245, 1);
    font-size: var(--main-font);
}

h2 {
    letter-spacing: 0.5px;
}

button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-loading-skeleton + br {
    display: none;
}
