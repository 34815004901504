.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
}


.content {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 1.5rem;
    flex-direction: column;
}