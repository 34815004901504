@import 'styles/rem.scss';

.wrapper {
    height: 6.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 4.5rem;
    & * {
        border-radius: rem(8px);
    }

    & .logo {
        display: flex;
        align-items: center;

        & .icon {
            width: rem(34px);
            height: rem(34px);
            margin-right: rem(20px);
        }

        & .name {
            width: rem(80px);
            height: rem(28px);
        }
    }

    & .right {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .devider {
            margin: 0 0.5rem;
        }

        & .nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 1rem;

            & .link {
                width: rem(45px);
                height: 1rem;
                margin: 0 1.5rem;
                &.last {
                    margin-right: rem(24px);
                }
            }
        }

        

        & .user {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 2.5rem;

            & .name {
                width: rem(58px);
                height: rem(22px);
            }

            & .avatar {
                height: 2rem;
                width: 2rem;
                margin-left: rem(16px);
            }
        }
    }
}

