.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;


    .title {
        width: 12rem;
        min-height: 3rem;
        padding: 0 5rem;
        margin: 2rem 0 3rem;
    }

    .contentWrapper {
        min-height: calc(100% - 5rem);
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .roles {
        display: flex;
        gap: 2.5rem;
    }

    .role {
        width: 19.5rem;
        height: 19.75rem;
        padding: 2.5rem 2rem;
    }

    .login {
        display: flex;
        width: 40rem;
        gap: 3rem;
        align-items: center;
        justify-content: center;
    }

    .loginWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1rem auto 0 auto;
        grid-template-columns: 1fr 1fr;
        width: 40rem;
        height: 15rem;
        flex-direction: column;
        gap: 2rem;

    }

    .loginText {
        width: 15rem;
        text-align: right;
        margin-right: 0.5rem;
        justify-self: right;
        position: relative;
        left: 1rem;
        display: flex;
        align-items: center;
    }

    .loginBtn {
        width: 10rem;
        height: 3rem;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5rem;
    height: 6.375rem;
    position: relative;
    z-index: 1;

    .logo {
        display: flex;
        width: 155px;
        height: 24px;
    }

}


.content {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 100%;
    align-items: center;

    .form {
        display: flex;
        border-radius: 0.5rem;
        width: 30rem;
        margin: 0 auto;
        padding: 2.5rem;
        height: 42rem;
    }
}