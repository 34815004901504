.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5rem;
    height: 6.375rem;
    position: relative;
    z-index: 1;

    .logo {
        display: flex;
        width: 155px;
        height: 24px;
    }
    
}


.content {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 100%;

    .form {
        display: flex;
        border-radius: 0.5rem;
        width: 30rem;
        margin: 0 auto;
        padding: 2.5rem;
        height: 45rem;
    }
}