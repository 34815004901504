.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 2.5em 5em;
    gap: 3rem;
    

    .content {
        display: flex;
        width: 50rem;
        background-color: white;
        box-shadow: 0 .3571428571rem 1.7142857143rem rgba(110, 128, 153, .3);
        border-radius: .5714285714rem;
        height: 100%;
        height: 100vh;
        flex-direction: column;
        padding: 2rem;
        gap: 1rem;
    }

    .column {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
    }

    .row {
        display: flex;
        width: 100%;
        // padding: 2rem;
    }
}