.container {
    display: flex;
    gap: 1.25rem;
    height: 100%;
    justify-content: center;

    .section {
        display: flex;
        flex-direction: column;
        background: white;
        // height: 100%;
        border-radius: .8571428571rem;
        width: 100%;
        height: fit-content;
    }

    .menuItems {
        padding: 0 2rem;
        display: flex;
        flex-direction: column;

        :nth-child(1) {
            width: 30%;
        }

        :nth-child(3) {
            width: 45%;
        }

        :nth-child(5) {
            width: 30%;
        }

        :nth-child(7) {
            width: 45%;
        }

        :nth-child(9) {
            width: 55%;
        }

        :nth-child(11) {
            width: 65%;
        }
    }

    .menuItem {
        margin: 1rem 0;
    }

    .header {
        display: flex;
        padding: .75rem 2.5rem .5714285714rem;
        justify-content: space-between;
        align-items: center;
        // margin-bottom: 2.5rem;
    }

    .headerItems {
        display: flex;
        gap: .8rem;
    }

    .headerItem {
        height: 1.5rem;
        margin: 0 ;
        width: 5rem;
    }

    .headerBtn {
        height: 2rem;
        width: 5rem;
    }

    .content {
        display: grid;
        grid-template-columns: 10rem 1fr;
        margin: 0 2.5rem;
        margin-bottom: 3rem;
    }

    .contentRaw {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        width: 85%;
        gap: 1rem;
    }

    .inputWrapper {
        display: flex;
        flex-direction: column;
        gap: 0.9rem;
        width: 100%;
    }

    .contentBody {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 100%;
    }
}