.container {
    margin: 0.9rem 3.1rem;
    margin-left: 2.3rem;

    .info {
        background-color: #fff;
        border-radius: .7142857143rem;
        display: grid;
        font-size: .875rem;
        // grid-template-columns: 22.1428571429rem 1fr .85fr 11.8571428571rem;
        height: 17.1rem;
    }

    .controls {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 2.2rem;

        .tabs {
            display: flex;
            gap: 2rem;
        }
    }

    .filters {
        display: flex;
        width: 100%;
        gap: 3rem;
        align-items: center;
        margin-top: 3rem;

        .filtersLeft {
            height: 3rem;
            display: flex;
        }

        .filtersRight {
            height: 3rem;
            width: 100%;
            display: flex;
            gap: 1.5rem;
        }
    }

    .tableHeader {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 2rem;

        .tableHeaderItem {
            height: 1rem;
            width: 5rem;
        }
    }

    .tableRow {
        display: flex;
        width: 100%;
        margin-top: 2.5rem;
        flex-direction: column;
        gap: 0.5rem;

        .tableRowItem {
            height: 6rem;
            width: 100%;
        }
    }
}