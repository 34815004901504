.tabs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 2rem;
    gap: 2rem;
    margin-top: 1rem;
    align-items: center;

    & .tab {
        width: 45rem;
        height: 1rem;
    }
    :nth-child(3) {
        width: 2.5rem;
        height: 2.5rem;
    }
}

.list {
    margin-top: 1rem;
}

.search {
    width: 27rem;
    height: 3rem;
    margin-top: 1rem;
}

.content {
    display: flex;
    width: calc(100% - 10rem);
    height: 100%;
    margin: 0 5rem;
    flex-direction: column;
}