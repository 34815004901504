@import 'styles/rem.scss';

.wrapper {
    height: 5.46875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5rem;
    & * {
        border-radius: rem(8px);
    }

    & .logo {
        display: flex;
        align-items: center;

        & .icon {
            width: rem(40px);
            height: rem(40px);
            margin-right: rem(20px);
        }

        & .name {
            width: rem(80px);
            height: rem(28px);
        }
    }

    & .right {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & .nav {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & .link {
                width: rem(34px);
                height: rem(34px);
                margin-right: rem(36px);
                &.last {
                    margin-right: rem(24px);
                }
            }
        }

        & .user {
            display: flex;
            align-items: center;
            justify-content: center;

            & .name {
                width: rem(80px);
                height: rem(22px);
            }

            & .avatar {
                height: rem(34px);
                width: rem(34px);
                margin-left: rem(16px);
            }
        }
    }
}
