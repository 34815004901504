.container {
    // width: 58.875em;
    position: relative;
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    padding: 0 3rem;
}