.container {
    display: flex;
    justify-content: center;
    height: 100%;

    .content {
        margin-top: 0.4rem;
        width: 66rem;
        height: calc(100% - 5.5rem);
        display: flex;
    }
}