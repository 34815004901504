.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .headerTitle {
            display: flex;
            gap: 1rem;
        }
    }

    .tabs {
        display: flex;
        gap: 2.5rem;

        :first-child {
            width: 3.5rem !important;
        }
    }

    .filters {
        display: flex;
        width: 100%;
        gap: 1.5rem;
        align-items: center;

        .filtersLeft {
            height: 3.5rem;
            width: 100%;
            display: flex;
        }

        .filtersRight {
            height: 3rem;
            width: 100%;
            display: flex;
            gap: 1.5rem;
        }
    }

    .rows {
        margin-top: -0.5rem;
        .rowItem {
            height: 3.5rem;
        }
    }
}